import { isServiceWorkerAvailable } from '@/utils/is-service-worker-available.ts';
import { traceError } from '@/utils/trace-error.ts';
import type { Mixpanel } from 'mixpanel-browser';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { HydratedRouter } from 'react-router/dom';
import { handleImportError } from './handle-import-error.ts';
import { initSentry } from './sentry.client.ts';

declare global {
  interface Window {
    mixpanel: Mixpanel;
  }
}

if (__WITH_SENTRY__) {
  initSentry();
}

if (isServiceWorkerAvailable()) {
  navigator.serviceWorker
    .register('/sw.js', { scope: '/' })
    .catch((err) =>
      traceError(
        new Error('Service Worker registration failed', { cause: err }),
      ),
    );
}

handleImportError();

startTransition(() => {
  hydrateRoot(document, <HydratedRouter />);
});
